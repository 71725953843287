<template>
  <div>
    <div class="mb-2 d-flex align-center" v-if="!sectionReadonly">
      <v-btn depressed color="primary" @click="dialog_template = true">テンプレートを変更</v-btn>
      <!-- <v-switch v-if="!readonly" @click="downsize = !downsize" :input-value="downsize" label="縮小して表示する" class="ml-8"></v-switch> -->
    </div>

    <div v-if="refMode" class="btn-area d-flex">
      <div>
        <v-btn icon @click="shoot = true" class="btCancel">
          <v-icon color="white">mdi-content-copy</v-icon>
        </v-btn>
      </div>
      <div class="ml-3">
        <v-btn icon @click="dl = true" class="btCancel">
          <v-icon color="white">mdi-download-outline</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="x-scroll" :style="!refMode ? scrollAreaStyle : ''">
      <v-card color="transparent" tile elevation="0" :disabled="sectionReadonly" :style="!refMode ? contentAreaStyle : ''">
        <v-container class="ma-0" :style="{width: mapWidth + 'px', maxWidth: 'none', fontSize: downsize ? '13px' : '16px'}">
          <v-capture v-model="shoot" :visible="noteMode" :dlTrigger="dl" @done:download="dl = $event">
            <template #activator>
              <div></div>
            </template>
            <!-- <template #downloader>
              <div></div>
            </template> -->
            <!-- 以下の括りが、列タイトル部分 -->
            <v-row :class="refMode ? 'px-3' : ''" :style="{fontSize: downsize ? '14px' : '16px'}">
              <v-col :class="'fixed-col' + (controlY ? '--control' : '') + ' headline title-tile'">
              </v-col>
              <v-col
                v-for="(item, i) in items" :key="i"
                class="title-tile"
                :style="{padding: downsize ? '5px' : '12px'}"
              >
                <span v-if="!controlX" v-html="item.title ? item.title.replace(/\r?\n/g, '<br>') : ''"></span>
                <div v-if="controlX">
                  <div>
                    <v-btn class="px-1" text :disabled="i == 0" color="#1F1F1F" @click="moveLeft(i)"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <!-- 列を移動 -->
                    <v-btn class="px-1" text :disabled="i == items.length-1" color="#1F1F1F" @click="moveRight(i)"><v-icon>mdi-arrow-right</v-icon></v-btn>
                    <br>
                    <v-btn class="px-1" text :disabled="items.length == 1" color="#1F1F1F" @click="removeCol(i)">列を削除</v-btn>
                    <v-btn class="px-1" text v-if="i == items.length-1" color="#1F1F1F" @click="addCol(i)">右に追加</v-btn>
                  </div>
                  <v-textarea background-color="white" hide-details v-model="item.title" rows="1" auto-grow></v-textarea>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-for="(sortItem, j) in journeySortList" :key="j"
              v-show="!hiddenFields.includes(sortItem.field)"
              :class="refMode ? 'px-3' : ''"
            >
              <!-- 以下の括りが、行タイトル部分 -->
              <v-col :class="'fixed-col' + (controlY ? '--control' : '') + ' title-tile'" :style="{fontSize: downsize ? '14px' : '16px'}">
                <!-- <span v-if="!controlY">{{sortItem.title ? sortItem.title.replace(/\r?\n/g, '<br>') : ''}}</span> -->
                <span v-if="!controlY" v-html="sortItem.title ? sortItem.title.replace(/\r?\n/g, '<br>') : ''"></span>
                <div v-if="controlY" class="d-flex">
                  <v-textarea background-color="white" hide-details v-model="sortItem.title" rows="1" auto-grow></v-textarea>
                  <div>
                    <v-btn class="px-1" text :disabled="j == 0" color="#1F1F1F" @click="moveUp(j)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                    <!-- 行を移動 -->
                    <v-btn class="px-1" text :disabled="j == journeySortList.length-1" color="#1F1F1F" @click="moveDown(j)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                    <br>
                    <v-btn class="px-1" text :disabled="sortItem.type == 'motivation' || sortItem.type == 'touchpoint'" color="#1F1F1F" @click="removeRow(j)">行を削除</v-btn>
                    <br>
                    <v-btn class="px-1" text v-if="j == journeySortList.length-1 && journeySortList.length < 11" color="#1F1F1F" @click="addText(j)">追加</v-btn>
                  </div>
                </div>
              </v-col>
              <!-- 以下の括りが、入力欄 -->
              <v-col
                v-for="(item, i) in items" :key="i"
                class="item-tile pa-0"
              >
                <!-- モチベーション -->
                <customer-journey-motivation
                  v-if="sortItem.type == 'motivation'"
                  v-model="item.motivation"
                  :readonly="readonly"
                ></customer-journey-motivation>
                <!-- タッチポイント -->
                <div
                  v-else-if="sortItem.type == 'touchpoint'"
                  class="item-tile--text"
                  @click="editTouchpoint(i, sortItem.field)"
                >
                  <v-chip
                    v-for="p in item[sortItem.field]" :key="p"
                    class="ma-1"
                    small
                  >
                    {{p}}
                  </v-chip>
                </div>
                <!-- テキスト -->
                <div
                  v-else
                  class="item-tile--text px-1"
                  @click="editText(i, sortItem.field)"
                  v-html="item[sortItem.field] ? item[sortItem.field].replace(/\r?\n/g, '<br>') : ''"
                ></div>
              </v-col>
            </v-row>
          </v-capture>
        </v-container>
      </v-card>
    </div>
    <div class="d-flex" v-if="!sectionReadonly">
      <v-switch v-if="!readonly" v-model="controlXVal" label="列を編集"></v-switch>
      <v-switch v-if="!readonly" v-model="controlYVal" label="行を編集" class="ml-8"></v-switch>
      <v-switch v-if="!readonly" @click="toggleHiddenfields('motivation')" :input-value="!hiddenFields.includes('motivation')" label="モチベーションを表示" class="ml-8"></v-switch>
      <!-- <v-switch v-if="!readonly" @click="toggleHiddenfields('touchpoint1'); toggleHiddenfields('touchpoint2');" :input-value="!hiddenFields.includes('touchpoint1')" label="タッチポイントを表示" class="ml-8"></v-switch> -->
    </div>

    <input-text-area-dialog v-model="dialog" :text="selectedItemText" @ok="finishDialog($event)" @cancel="closeDialog()"></input-text-area-dialog>
    <input-touchpoint-dialog v-model="dialog2" :selectItems="selectedItemTouchpoint" :editProp="editProp" @ok="finishDialog($event)" @cancel="closeDialog()"></input-touchpoint-dialog>
    <template-selector v-model="dialog_template" @ok="changeTemplate($event)"></template-selector>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CustomerJourneyMotivation from '../molecules/CustomerJourneyMotivation.vue'
import InputTextAreaDialog from '../atoms/InputTextAreaDialog.vue'
import InputTouchpointDialog from '../atoms/InputTouchpointDialog.vue'
import VCapture from "../atoms/VCapture.vue";

import TemplateSelector from './CustomerJourneyMap_TemplateSelector.vue'
import {templates} from './CustomerJourneyMap_template.js'

export default {
  components: {
    CustomerJourneyMotivation,
    InputTextAreaDialog,
    InputTouchpointDialog,
    TemplateSelector,
    VCapture,
  },
  props: ['value', 'sectionReadonly', "noteMode", "refMode"],
  data() {
    return {
      dialog: false,
      dialog2: false,
      editRow: null,
      editProp: null,
      selectedItemText: null,
      selectedItemTouchpoint: null,

      dialog_template: false,

      controlXVal: false,
      controlYVal: false,
      readonly: false,

      downsize: true,

      items: [],

      journeySortList: [],

      showMotivation: true,
      hiddenFields: [],

      shoot: false,
      dl: false,
    };
  },
  computed: {
    ...mapGetters("relativeScrollableArea", [ "scrollAreaStyle", "contentAreaStyle" ]),
    ...mapGetters(["project"]),

    mapWidth() {
      return (this.controlY ? 400 : 200) + 280 * this.items.length
    },
    controlX() {
      return !this.readonly && this.controlXVal
    },
    controlY() {
      return !this.readonly && this.controlYVal
    },
  },
  mounted() {
    this.initData(this.value);
  },
  methods: {
    moveLeft(i) {
      this.items.splice(i-1, 2, this.items[i], this.items[i-1]);
    },
    moveRight(i) {
      this.items.splice(i, 2, this.items[i+1], this.items[i]);
    },
    removeCol(i) {
      this.items.splice(i, 1);
    },
    addCol() {
      this.items.push(
        {
          title: 'タイトル',
          journey1: null,
          journey2: null,
          journey3: null,
          journey4: null,
          journey5: null,
          journey6: null,
          journey7: null,
          journey8: null,
          journey9: null,
          journey10: null,
          motivation: [0, 0, 0],
          touchpoint: null,
        }
      )
    },
    moveUp(i) {
      this.journeySortList.splice(i-1, 2, this.journeySortList[i], this.journeySortList[i-1]);
    },
    moveDown(i) {
      this.journeySortList.splice(i, 2, this.journeySortList[i+1], this.journeySortList[i]);
    },
    removeRow(i) {
      this.journeySortList.splice(i, 1);
    },
    addText() {
      const field = this._findVacancy();
      this.journeySortList.push(
        {
          title: 'タイトル',
          field: field,
          type: 'text',
        },
      )
    },
    editText(row, propName) {
      if(this.readonly) return
      this.editRow = row
      this.editProp = propName
      this.selectedItemText = this.items[this.editRow][this.editProp]
      
      this.dialog = true
    },
    editTouchpoint(row, propName) {
      if(this.readonly) return
      this.editRow = row
      this.editProp = propName
      this.selectedItemTouchpoint = this.items[this.editRow][this.editProp]
      
      this.dialog2 = true
    },
    finishDialog(val) {
      this.items[this.editRow][this.editProp] = val
      this.closeDialog()
      // this.$emit('input', {items: this.items, journeySortList: this.journeySortList})
      this.outputData();
    },
    closeDialog() {
      this.dialog = false
      this.dialog2 = false
      this.editRow = null
      this.editProp = null
      this.selectedItemText = null
      this.finishTouchpoint = null
    },

    changeTemplate(e) {
      this.items = e.items
      this.journeySortList = e.journeySortList
      this.hiddenFields = e?.hiddenFields || [];
    },

    
    _findVacancy() {
      const fields = this.journeySortList.map(n => n.field)

      for (let step = 1; step <= 10; step++) {
        if (!fields.some(n => n == ('journey' + step))) return 'journey' + step
      }

      return null
    },

    initData(val) {
      if(val) {
        console.log(val)
        this.items = val.items
        this.journeySortList = val.journeySortList
        // ph2追加 非表示フィールド
        this.hiddenFields = val?.hiddenFields || [];
      } else {
        // 業界が市価の場合は歯科用のテンプレートで、それ以外は汎用テンプレートで初期化
        let template = null
        if(this.project.industry == '歯科') {
          template = templates.filter(template => template.name == '歯科医院用①')[0]
        } else {
          template = templates.filter(template => template.name == '汎用テンプレート')[0]
        }
        const t = JSON.parse(JSON.stringify(template))
        this.items = t.items
        this.journeySortList = t.journeySortList
        this.hiddenFields = t?.hiddenFields || [];
      }
    },

    outputData() {
      this.$emit('input', {items: this.items, journeySortList: this.journeySortList, hiddenFields: this.hiddenFields})
    },

    toggleHiddenfields(fieldName) {
      if(this.hiddenFields.includes(fieldName)) {
        const idx = this.hiddenFields.indexOf(fieldName);
        this.hiddenFields.splice(idx, 1);
      } else {
        this.hiddenFields.push(fieldName);
      }
    },
  },
  watch: {
    value(val) {
      this.initData(val)
    },
    items: {
      handler(){
        // console.log('items')
        // this.$emit('input', {items: this.items, journeySortList: this.journeySortList})
        this.outputData();
      },
      deep: true,
    },
    journeySortList: {
      handler(){
        // console.log('journeySortList')
        // this.$emit('input', {items: this.items, journeySortList: this.journeySortList})
        this.outputData();
      },
      deep: true,
    },
    hiddenFields: {
      handler(){
        this.outputData();
      },
      deep: true,
    },
    // items() {
    //   console.log('items')
    //   this.$emit('input', {items: this.items, journeySortList: this.journeySortList})
    // }, 
    // journeySortList() {
    //   console.log('journeySortList')
    //   this.$emit('input', {items: this.items, journeySortList: this.journeySortList})
    // },
  },
}
</script>
<style lang="scss" scoped>
.fixed-col {
  position: sticky;
  left: 0;
  width: 200px;
  min-width: 200px;
  flex-grow: 0;
  z-index: 1;
}
.fixed-col--control {
  position: sticky;
  left: 0;
  width: 400px;
  min-width: 400px;
  flex-grow: 0;
  z-index: 1;
}

.title-tile {
  border: 1px solid #b3b3b3;
  background-color: #ECEFF1;
  color: #1F1F1F;
  text-align: center;
  word-break: break-all;
}
.item-tile {
  border: 1px solid #b3b3b3;
  background-color: white;
  max-width: 280px;

  &--text {
    min-height: 60px;
    height: 100%;
    cursor: pointer;
    &:hover {
      background-color: #f2f7f8;
    }
  }
}

.x-scroll{
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar{
      height: 8px;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track{
      background: #fff;
      border: none;
      border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb{
      background: #ccc;
      border-radius: 10px;
      box-shadow: none;
  }
}
</style>
