<template>
  <v-container class="pa-0">
    <v-row v-for="(row, i) in 5" :key="i" no-gutters class="mt-0">
      <v-col 
        v-for="(col, j) in 3" :key="j" 
        class="motivation-area" :style="{borderWidth: row == 5 ? 0 : '1px'}" 
        @click="_click(i, j)"
      >
        <i v-if="values[j] == 5 - i" :class="motivationClass(i)" :style="{color: motivationColor(i)}"></i>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    value: Array,
    readonly: Boolean,
  },
  data() {
    return {
      values: null,
    };
  },
  created() {
    this.values = this.value || [0, 0, 0];
  },
  computed: {
  },

  methods: {
    _click(row, col) {
      if(this.readonly) return

      let val = 5-row
      if(this.values[col] == val) {
        val = 0
      }
      this.values.splice(col, 1, val)
    },
    motivationClass(val) {
      let clazz = 'motivation-mark mdi ';

      switch (val) {
        case 0:
          return clazz + 'mdi-emoticon-outline';
        case 1:
          return clazz + 'mdi-emoticon-happy-outline';
        case 2:
          return clazz + 'mdi-emoticon-neutral-outline ';
        case 3:
          return clazz + 'mdi-emoticon-sad-outline';
        case 4:
          return clazz + 'mdi-emoticon-frown-outline';
      }
    },
    motivationColor(val) {
      switch (val) {
        case 0:
        case 1:
          return '#27ae45';
        case 2:
        case 3:
          return '#f0c224';
        case 4:
          return '#c50a13';
      }
    },
  },
  watch: {
    value(val) {
      this.values = val || [0, 0, 0];
    },
  },
}
</script>
<style lang="scss" scoped>
.motivation-area {
  border-bottom: 1px dotted gray;
  height: 26px;
  display: flex;
  justify-content: center;

  .motivation-mark {
    font-size: 24pt;
    line-height: 0.7;
  }
}
</style>