<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="400"
  >
    <v-card height="420">
      <v-card-title>{{caption}}</v-card-title>
      <v-card-text>
        <v-img v-if="imageSrc" :src="imageSrc"></v-img>

        <v-card v-else width="100%" height="300">
          <v-card
            flat
            height="100%"
            @dragover.prevent="isDragging = true"
            @dragenter.prevent="isDragging = true"
            @dragleave.prevent="isDragging = false"
            @drop.prevent="onDrop($event)"
            :style="{backgroundColor: isDragging ? 'lightgray' : 'gray' ,color: '#fff'}"
            class="d-flex align-center justify-center"
          >
            このエリアにファイルをドロップしてください
          </v-card>
        </v-card>
        <div style="height: 20px">{{message}}</div>
        
      </v-card-text>

      <v-card-actions>
        <input 
          style="display: none" type="file"
          ref="fileInput"
          @change="selectedFile"
        >
        <v-btn small @click="clickSelect">
          ファイルを選択
        </v-btn>
        <v-btn color="error" small @click="deselect">
          <v-icon color="white">mdi-trash-can</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" small :disabled="!needUpload" @click="upload">
          <v-icon color="white">mdi-upload</v-icon>
          upload
        </v-btn>
        <v-btn color="primary" small :disabled="needUpload" @click="finish">
          ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script>
import {RepositoryFactory} from '../../../api/RepositoryFactory'
// import axios from 'axios'

export default {
  props: ['value', 'src', 'caption', 'func'],
  data() {
    return {
      dialog: false,
      isDragging: false,
      imageSrc: null,
      imageUrl: null,
      
      extension: 'png',
      fileInstance: null,

      message: null,
      needUpload: false,
    }
  },
  methods: {
    selectedFile() {
      this.message = null

      if(this.$refs.fileInput.files.length == 0) {
        return
      }

      const file = this.$refs.fileInput.files[0]

      if(file.size > 1024 * 1024) {
        this.message = '上限は1MBまでです'
        return
      }
      if(!file.type.startsWith('image/')) {
        this.message = '画像ファイルを選択してください'
        return
      }

      this.extension = file.name.split('.').pop();

      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', () => {
        this.imageSrc = fr.result
        this.needUpload = true
        this.fileInstance = file
      })

    },
    clickSelect(){
      this.$refs.fileInput.click()
    },
    async upload() {
      this.message = 'アップロード中...'

      // const form = {
      //   image: this.imageSrc,
      //   extension: this.extension,
      //   func: this.func,
      // }
      // const res = await axios.post('http://localhost:3000/api/v1/images', form);
      // this.imageUrl = res.data.data.imageUrl

      // this.imageUrl = 'favicon.ico'

      // var params = new FormData();
      // params.append('file', this.fileInstance);
      // const res = await axios.post('http://localhost:3000/api/v1/images2/' + this.func, params);
      // this.imageUrl = res.data.data.imageUrl

      const ImagesRepository = RepositoryFactory.get('images')
      const response = await ImagesRepository.upload2(this.fileInstance, this.func)

      if (response.data.result && response.data.resultCode === '0000') {
        this.imageUrl = response.data.data.imageUrl
        this.message = 'アップロードしました'
      } else {
        this.message = 'アップロードに失敗しました'
      }

      this.needUpload = false
    },
    deselect() {
      this.message = null
      this.imageSrc = null
      this.imageUrl = null
      this.needUpload = false
    },
    finish() {
      this.dialog = false
      this.$emit('change-url', this.imageUrl)
    },

    onDrop(e) {
      e.stopPropagation();
      this.isDragging = false;
      if(e.dataTransfer.files.length > 0) {
        this.$refs.fileInput.files = e.dataTransfer.files
        this.selectedFile()
      }
    },
  },
  watch: {
    value(val) {
      if(val) {
        this.dialog = val
        this.message = null
        this.imageSrc = this.src
        this.imageUrl = this.src
      }
    },
    dialog(val) {
      if(!val) {
        this.$emit('input', false)
      }
    },
  },
}
</script>
