<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title>{{ label }}</v-card-title>
      <v-card-text>
        <!-- <v-radio-group v-model="radios" mandatory row>
          <v-radio label="オンライン" value="online"></v-radio>
          <v-radio label="オフライン" value="offline"></v-radio>
        </v-radio-group> -->
        <v-combobox
          v-model="selectItemsVal"
          :items="nowMeasures"
          :item-text="measureKey"
          :item-value="measureKey"
          :return-object=false
          outlined
          multiple
          chips
          deletable-chips
          :menu-props="{ maxHeight: 170 }"
        >
          <!-- <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
            >
              <span class="pr-2">
                {{item}}
              </span>
              <v-icon
                small
                @click="parent.selectItem(item)"
              >
                $delete
              </v-icon>
            </v-chip>
          </template> -->
        </v-combobox>
        <div style="height: 150px"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="green darken-1"
          text
          @click="
            dialog = false;
            $emit('ok', selectItemsVal.length > 0 ? selectItemsVal : null);
          "
        >
          OK
        </v-btn>
        <v-btn
          color="gray darken-1"
          text
          @click="
            dialog = false;
            $emit('cancel');
          "
        >
          CANCEL
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import MasterData from '../../../utils/MasterData'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: Boolean,
    selectItems: null,
    editProp: null,
    label: {
      type: String,
      default: "施策・タッチポイントを選択または入力してください",
    },
  },
  data() {
    return {
      dialog: false,
      selectItemsVal: null,
      radios: null,
      measureKey: null,
      MasterData: {}
    };
  },
  computed: {
    ...mapGetters(["project"]),

    isDentistry() {
      return this.project.industry == "歯科";
    },
    isRealEstate() {
      return this.project.industry == "不動産";
    },
    isBuilding() {
      return this.project.industry == "建築";
    },

    onlineMeasures() {
      let rtn;
      if(this.isDentistry) {
        rtn = this.MasterData.onlineMeasuresDentistry;
      } else if(this.isRealEstate) {
        rtn = this.MasterData.onlineMeasuresRealEstate;
      } else if(this.isBuilding) {
        rtn = this.MasterData.onlineMeasuresBuilding;
      } else {
        rtn = this.MasterData.onlineMeasuresOther;
      }

      return rtn;
    },

    offlineMeasures() {
      let rtn;
      if(this.isDentistry) {
        rtn = this.MasterData.offlineMeasuresDentistry;
      } else if(this.isRealEstate) {
        rtn = this.MasterData.offlineMeasuresRealEstate;
      } else if(this.isBuilding) {
        rtn = this.MasterData.offlineMeasuresBuilding;
      } else {
        rtn = this.MasterData.offlineMeasuresOther;
      }

      return rtn;
    },

    nowMeasures() {
      let options = [];
      if (this.radios == "online") {
        options = this.onlineMeasures;
        this.measureKey = "onlineMeasures";
      } else if (this.radios == "offline") {
        options = this.offlineMeasures;
        this.measureKey = "offlineMeasures";
      }
      return options;
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.dialog = val;
        this.selectItemsVal = this.selectItems ? this.selectItems : [];
        this.radios = this.editProp == 'touchpoint1' ? 'online' : 'offline';
      }
    },
    dialog(val) {
      if (!val) {
        this.$emit("input", val);
      }
    },
  },
  mounted() {
    this.MasterData = MasterData
  }
};
</script>
