<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>テンプレートを選択してください</v-card-title>
      <v-card-text>
        <v-radio-group v-model="selected">
          <v-radio
            v-for="(t, i) in templateList"
            :key="i"
            :label="t.name"
            :value="i"
          ></v-radio>
        </v-radio-group>

        <div class="text-subtitle-2 red--text font-weight-bold">
          注意！ テンプレート適用すると入力内容は失われます
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="dialog = false; $emit('ok', JSON.parse(JSON.stringify(templateList[selected])))">
          適用
        </v-btn>
        <v-btn color="gray darken-1" text @click="dialog = false; $emit('cancel')">
          キャンセル
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {templates} from './CustomerJourneyMap_template.js'
import {mapGetters} from 'vuex';

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      selected: 0, 
    }
  },
  computed: {
    ...mapGetters(['project']),

    templateList() { return templates.filter(template => {
      // 業界指定がないテンプレートは常に表示する
      if(!('industry' in template)) return true;

      // 業界指定のあるテンプレートの出し分け
      if(["歯科", "建築", "不動産"].includes(this.project.industry)) {
        return template.industry == this.project.industry
      } else {
        return template.industry == "その他"
      }
    })}
  },
  watch: {
    value(val) {
      if(val) {
        this.dialog = val
      }
    },
    dialog(val) {
      if(!val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>
