<template>
  <div class="d-flex flex-column align-center">
    <!-- 画像選択ダイアログ -->
    <image-select-dialog
      v-model="dialog"
      :src="personaImg"
      @change-url="changeUrl($event)"
      caption="アイコンを変更"
      :func="4"
    ></image-select-dialog>
    <!-- ペルソナ画像 -->
    <v-hover v-slot="{ hover }">
      <v-card rounded="circle" elevation="0" color="transparent">
        <v-avatar size="100" class="mb-1">
          <img v-if="personaImg" :src="personaImg" />
          <v-icon v-else size="100">mdi-account-circle</v-icon>
        </v-avatar>
        <v-btn
          class="persona-add__icon-btn"
          :class="{ 'persona-add__icon-btn--hover': hover }"
          x-small
          fab
          color="primary"
          @click="dialog = true"
        >
          <v-icon color="white">mdi-pencil</v-icon>
        </v-btn>
      </v-card>
    </v-hover>
    <v-text-field
      v-model="personaName"
      :readonly="readonly"
    ></v-text-field>
  </div>
</template>
<script>
import ImageSelectDialog from "../atoms/ImageSelectDialog.vue";

export default {
  components: {
    ImageSelectDialog,
  },
  props: ["value", "personaImg", "readonly"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    personaName: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    // データの更新
    changeUrl(newUrl) {
      this.dialog = false
      this.$emit('change-url', newUrl)
    }
  },
};
</script>
<style lang="scss" scoped>
.persona-add {
  &__icon-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    opacity: 0;

    &--hover {
      opacity: 1;
    }
  }

  &__form-button-wrap {
    width: 95px;
  }
}
</style>