<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-textarea v-model="textVal" :label="label" auto-grow hide-details="auto" class="pa-5" autofocus></v-textarea>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="dialog = false; $emit('ok', textVal)">
          OK
        </v-btn>
        <v-btn color="gray darken-1" text @click="dialog = false; $emit('cancel')">
          CANCEL
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    text: null,
    label: {
      type: String,
      default: 'テキストを入力してください',
    },
  },
  data() {
    return {
      dialog: false,
      textVal: null,
    }
  },
  watch: {
    value(val) {
      if(val) {
        this.dialog = val
        this.textVal = this.text
      }
    },
    dialog(val) {
      if(!val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>
