<template>
  <div>
    <!-- キャプチャーのアクションをするためのslot -->
    <div class="btn-area d-flex mb-8" v-if="visible">
      <div>
        <slot name="activator" :onClickActivator="onClickActivator">
          <v-btn icon @click="onClickActivator" class="btCancel">
            <v-icon color="white">mdi-content-copy</v-icon>
          </v-btn>
        </slot>
      </div>
      <!-- <div class="ml-3">
        <slot name="downloader" :download="download">
          <v-btn icon @click="download" class="btCancel">
            <v-icon color="white">mdi-download-outline</v-icon>
          </v-btn>
        </slot>
      </div> -->
    </div>

    <!-- キャプチャー対象のhtml要素が入るslot -->
    <div ref="target">
      <slot></slot>
    </div>

    <!-- コピーした通知 -->
    <v-snackbar v-model="notice" timeout="2000" top>
      <div class="text-center">{{ message }}</div>
    </v-snackbar>
  </div>
</template>

<script>
// import html2canvas from "html2canvas";
// textareaバグに対応されているブランチを使用
// https://github.com/niklasvh/html2canvas/issues/2008#issuecomment-627455832
import html2canvas from "@nidi/html2canvas";

export default {
  props: {
    visible: Boolean,
    filePrefix: {
      type: String,
      default: "image",
    },
    value: Boolean,
    dlTrigger: Boolean,
  },
  data() {
    return {
      notice: false,
      message: "クリップボードにコピーしました。",
    };
  },
  methods: {
    onClickActivator() {
      const options = {}

      html2canvas(this.$refs.target, options).then((canvas) => {
        
        canvas.toBlob((blob) => {
          // http環境ではクリップボードの使用が許可されていないので動かない
          if (window.navigator.clipboard === undefined) {
            console.log("クリップボードの使用が許可されていません");
            return;
          }

          // クリップボードにコピー
          window.navigator.clipboard
            .write([new ClipboardItem({ "image/png": blob })])
            .then(() => {
              this.notice = true;
            });
        });
      });
    },

    download() {
      const options = {}

      html2canvas(this.$refs.target, options).then((canvas) => {
        var dt = new Date();
        var y = dt.getFullYear();
        var m = ("00" + (dt.getMonth()+1)).slice(-2);
        var d = ("00" + dt.getDate()).slice(-2);
        var dtStr = y + m + d;
        
        let link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${this.filePrefix}_${dtStr}`;
        link.click();
      });
    }
  },
  watch: {
    value(to) {
      // v-modelでtrueが送られるとアクションを実行する
      if(!to) return;

      this.onClickActivator()
      
      this.$emit('input', false);
    },

    dlTrigger(to) {
      // v-modelでtrueが送られるとアクションを実行する
      if(!to) return;

      this.download()
      
      this.$emit('done:download', false);
    },
    
  }
};
</script>

<style scoped>
.btn-area {
  z-index: 9999;
}
</style>
