<template>
  <div>
    <v-tabs
      v-model="tabs"
      vertical
      @change="tabChange($event)"
    >
      <v-tab
        v-for="(persona, i) in persona.data"
        :key="persona.id"
        class="justify-start tab"
      >
        <v-avatar
          size="36px"
          class="mr-3"
        >
          <img
            v-if="persona.imageUrl"
            :src="persona.imageUrl"
          >
          <v-icon
            v-else
            x-large
          >mdi-account-circle</v-icon>
        </v-avatar>
        <div class="persona-name">{{ persona.personaName }}</div>

        <v-btn
          v-show="!readonly"
          x-small
          color="gray"
          plain
          fab
          @click="showDialog(i)"
        >
          <v-icon
            color="gray"
          >mdi-trash-can</v-icon>
        </v-btn>

      </v-tab>

      <!-- dialog -->
      <v-dialog
        v-model="dialog"
        persistent
        max-width="360px"
      >
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>本当にペルソナを削除してもよろしいですか？</v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="pa-3 mr-3 text-body-1"
              width="126"
              color="primary"
              large
              depressed
              @click="dialog = false; deletePersona()"
            >
              削除
            </v-btn>
            <v-btn
              class="pa-3 text-body-1"
              width="126"
              color="grey lighten-2"
              large
              depressed
              @click="dialog = false"
            >
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- dialog -->

      <div class="justify-start mt-3">
        <v-btn
          v-show="!readonly"
          depressed
          color="primary"
          @click="addPersona()"
        >
          ペルソナ追加
          <v-icon
            small
            color="white"
            class="ml-1"
          >mdi-plus-thick</v-icon>
        </v-btn>
      </div>

      <v-tab-item
        v-for="persona in persona.data"
        :key="persona.id"
        class="tab-item-border"
        transition="slide-y-transition"
      >
        <v-card flat>
          <v-card-text>
            <v-row class="ma-0 pa-0">
              <v-col align="right" class="ma-0 pa-0 pb-2">
                <input-sample class="input-sample" :refDoc="true" reference="PersonaInfo_persona.txt" label="ペルソナ"></input-sample>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-7 mx-16">
              <v-col sm="4" md="3">
                <persona-add
                  v-model="persona.personaName"
                  @change-url="persona.imageUrl = $event"
                  :personaImg="persona.imageUrl"
                  :readonly="readonly"
                ></persona-add>
              </v-col>

              <v-col sm="8" md="9">
                <v-row>
                  <v-col sm="12" md="6" class="py-0">
                    <v-select
                      v-model="persona.gender"
                      :items="genderOption"
                      label="性別"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-select>
                    <v-select
                      v-model="persona.age"
                      :items="ageOption"
                      label="年齢"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-select>
                    <v-text-field
                      v-model="persona.familyStructure"
                      label="家族構成"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-text-field>
                    <v-select
                      v-model="persona.job"
                      :items="jobOption"
                      label="職業"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-select>
                  </v-col>
                  <v-col sm="12" md="6" class="py-0">
                    <v-select
                      v-model="persona.incomeYear"
                      :items="incomeYearOption"
                      label="年収"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-select>
                    <v-text-field
                      v-model="persona.residenceArea"
                      label="居住エリア"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                      v-model="persona.hobbies"
                      label="趣味・趣向"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-text-field>
                    <v-text-field
                      v-model="persona.senseOfValue"
                      label="価値観"
                      outlined
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="12" md="6" class="py-0 mb-7">
                <v-select
                  v-model="persona.transportationType"
                  :items="transportationTypeOption"
                  label="交通手段"
                  outlined
                  multiple
                  small-chips
                  deletable-chips
                  hide-details
                  :clearable="!readonly"
                  :readonly="readonly"
                ></v-select>
              </v-col>
              <v-col sm="12" md="6" class="py-0 mb-7">
                <v-combobox
                  v-model="persona.collectInfo"
                  :items="collectInfoOption"
                  label="情報収集手段"
                  outlined
                  multiple
                  small-chips
                  deletable-chips
                  hide-details
                  :clearable="!readonly"
                  :readonly="readonly"
                ></v-combobox>
              </v-col>
              <v-col  sm="12" md="6" class="py-0 mb-7">
                <v-textarea
                  v-model="persona.features"
                  label="特長"
                  outlined
                  auto-grow
                  :clearable="!readonly"
                  :readonly="readonly"
                ></v-textarea>
              </v-col>
              <v-col  sm="12" md="6" class="py-0 mb-7">
                <v-textarea
                  v-model="persona.needs"
                  label="ニーズ"
                  outlined
                  auto-grow
                  :clearable="!readonly"
                  :readonly="readonly"
                ></v-textarea>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-divider class="mb-4"></v-divider>

    <customer-journey-map v-if="persona.data.length > 0" v-model="customerJourneyData" :sectionReadonly="readonly"></customer-journey-map>

  </div>
</template>
<script>
import PersonaAdd from '../molecules/PersonaAdd.vue'
import CustomerJourneyMap from '../organisms/CustomerJourneyMap';
import InputSample from '../atoms/InputSample.vue';

export default {
  components: {
    PersonaAdd,
    CustomerJourneyMap,
    InputSample,
  },
  props: ['value', 'readonly'],
  data () {
    return {
      tabs: 0,
      currentTab: 0,

      persona: {
        data: [
          {
            personaName: '',
            imageUrl: '',
            gender: '',
            age: '',
            familyStructure: '',
            job: '',
            incomeYear: '',
            residenceArea: '',
            transportationType: '',
            hobbies: '',
            senseOfValue: '',
            collectInfo: '',
            features: '',
            needs: '',
            customerJourneyData: null,
          }
        ],
      },
      customerJourneyData: null,
      genderOption: ['男性', '女性'],
      ageOption: ['10代', '20代前半', '20代後半', '30代前半', '30代後半', '40代前半', '40代後半', '50代', '60代', '70代以上'],
      incomeYearOption: ['100万未満', '100～200万未満', '200万～400万未満', '400～600万未満', '600～800万未満', '800万～1000万未満', '1000万以上'],
      transportationTypeOption: ['電車', 'バス', 'タクシー', 'マイカー', 'カーシェア', '自転車', '徒歩'],
      collectInfoOption: ['インターネット', 'ニュースアプリ', 'TV', '新聞', '雑誌', '業界誌', 'ラジオ', 'コミュニティ'],
      jobOption: ['会社員', '公務員', '自営業', '会社役員', '経営者', '自由業', '専業主婦/夫', '中学生', '高校生', '専門学校/大学生', 'パート/アルバイト', '無職'],
      //favoriteSnsOption: ['Facebook', 'Instagram', 'Twitter', 'TikTok', 'LINE'],
      tab: null,
      dialog: false,
      delete_data: null,
    }
  },
  created(){
    if(this.value) {
      this.persona = this.value
      this.customerJourneyData = this.persona.data[0].customerJourneyData
    }
  },
  watch: {
    value(val) {
      this.persona = val
    },
    customerJourneyData(val) {
      if(JSON.stringify(this.persona.data[this.currentTab].customerJourneyData) != JSON.stringify(val)) {
        this.persona.data[this.currentTab].customerJourneyData = val
      }
    },
  },
  methods: {
    deletePersona: function(){
      this.persona.data.splice(this.delete_data, 1)
    },
    addPersona: function(){
      this.persona.data.push(
          {
            personaName: 'persona',
            imageUrl: '',
            gender: '',
            age: '',
            familyStructure: '',
            job: '',
            incomeYear: '',
            residenceArea: '',
            transportationType: '',
            hobbies: '',
            senseOfValue: '',
            collectInfo: '',
            features: '',
            needs: '',
            customerJourneyData: null,
          }
        )

      this.tabs = this.persona.data.length-1
      this.tabChange(this.persona.data.length-1)
    },
    showDialog: function(i){
      this.dialog = true
      this.delete_data = i
    },
    tabChange: function(i) {
      if (typeof i !== 'undefined') {
        this.currentTab = i
        this.customerJourneyData = this.persona.data[i].customerJourneyData
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.input-sample {
  margin-top: -3px;
}
</style>
<style>
.tab{
  max-width: 200px!important;
  min-width: 200px!important;
}
.persona-name{
    width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
.tab-item-border{
  border-left: solid 3px #ECEFF1;
}
.v-avatar img {
  object-fit: cover; 
}
@media screen and (max-width: 1024px) {
  .tab{
    width: 200px!important;
  }
}
/* セレクトボックスの選択肢の色をreadonly時に変化させない対応 */
.theme--light .v-select .v-select__selection--disabled {
  color: rgba(0,0,0,.87)
}
</style>